export default class HeroBlockVideo {
  $el;
  $video;
  video;
  dimension;
  mediaDimension;
  constructor(el) {
    this.$el = $(el);
    this.dimension = {
      width: 0,
      height: 0,
      ratio: 1
    };
    this.mediaDimension = {
      width: 0,
      height: 0,
      ratio: 1
    };
    this.video = this.$el.find("video")[0];
    if (!this.video) {
      console.warn("[HeroBlock]: no <video> found in the hero block", el);
      return;
    }
    this.$video = $(this.video);
    let canIUseWebm;
    if (this.video.canPlayType) {
      canIUseWebm = "" !== this.video.canPlayType('video/webm; codecs="vp8, vorbis"') && this.video.dataset.webm === "true";
    }
    if (canIUseWebm) {
      let mp4Source = $("source", this.$video).attr("src");
      $("source", this.$video).attr(
        "src",
        mp4Source + "/jcr:content/renditions/cq5dam.video.webm.webm"
      );
      $("source", this.$video).attr("type", 'video/webm; codecs="vp8, vorbis"');
      this.video.load();
    }
    this.getMediaDimensions = this.getMediaDimensions.bind(this);
    this.video.addEventListener("progress", this.getMediaDimensions);
    this.video.addEventListener("play", this.getMediaDimensions);
  }
  getMediaDimensions() {
    if (this.video.videoWidth) {
      this.mediaDimension.width = this.video.videoWidth;
      this.mediaDimension.height = this.video.videoHeight;
      this.mediaDimension.ratio = this.video.videoWidth / this.video.videoHeight;
      this.video.removeEventListener("progress", this.getMediaDimensions);
      this.video.removeEventListener("play", this.getMediaDimensions);
    }
  }
}
window.addEventListener("load", (e) => {
  if ($(".js-hero-block-video").length) {
    $(".js-hero-block-video").each(
      (index, heroBlockHTMLElement) => {
        new HeroBlockVideo(heroBlockHTMLElement);
      }
    );
  }
});
