import HeroBlock from "./HeroBlock";
import HeroBlock3D from "./HeroBlock3D";
import HeroBlockEcommerceConnector from "./HeroBlockEcommerceConnector";
import HeroBlockVideo from "./HeroBlockVideo";
export default {
  HeroBlock,
  HeroBlock3D,
  HeroBlockEcommerceConnector,
  HeroBlockVideo
};
