import AbstractComponent from "../AbstractComponent";
export default class HeroBlock3D extends AbstractComponent {
  $el;
  app;
  loader;
  imageContainer;
  filter;
  mousePosition;
  dimension;
  imageDimension;
  displacementFactor = 0.015;
  maxDisplacement = 1;
  constructor(el) {
    super();
    this.$el = $(el);
    this.app = new PIXI.Application(800, 600, {
      autoResize: true,
      backgroundColor: 0
    });
    this.imageContainer = new PIXI.Container();
    this.app.stage.addChild(this.imageContainer);
    this.mousePosition = {
      x: 0,
      y: 0
    };
    this.dimension = {
      width: 800,
      height: 500,
      ratio: 1
    };
    this.imageDimension = {
      width: 0,
      height: 0,
      ratio: 1
    };
    this.update = this.update.bind(this);
    if (this.waitForLazyLoading()) {
      let $img = this.$el.find("img");
      if ($img.length > 0) {
        $img[0].addEventListener("load", this.load.bind(this));
      }
    } else {
      this.load();
    }
  }
  waitForLazyLoading() {
    let $cmpImageIsLoading = this.$el.find(".cmp-image__image--is-loading");
    if ($cmpImageIsLoading.length > 0) {
      return true;
    }
    return false;
  }
  load() {
    this.loader = new PIXI.loaders.Loader();
    this.loader.add("image", this.$el.find("img").attr("src"));
    this.loader.add("depthMap", this.$el.find("img").attr("data-depth-map"));
    this.loader.once("complete", this.init.bind(this));
    this.loader.load();
  }
  init() {
    const fg = new PIXI.Sprite(this.loader.resources.image.texture);
    this.imageDimension.width = this.loader.resources.image.texture.orig.width;
    this.imageDimension.height = this.loader.resources.image.texture.orig.height;
    this.imageDimension.ratio = this.imageDimension.width / this.imageDimension.height;
    this.imageContainer.addChild(fg);
    const _d = new PIXI.Sprite(this.loader.resources.depthMap.texture);
    this.filter = new PIXI.filters.DisplacementFilter(_d, 0);
    this.filter.autoFit = true;
    fg.filters = [this.filter];
    this.imageContainer.addChild(_d);
    _d.renderable = false;
    this.$el.on("mousemove", this.handleMouseMove.bind(this));
    if (window.hasOwnProperty("DeviceOrientationEvent")) {
      window.addEventListener(
        "deviceorientation",
        this.handleOrientation.bind(this)
      );
    }
    this.resize();
    this.$el.find(".cmp-image").empty().append(this.app.view);
  }
  handleMouseMove(e) {
    const _offset = this.$el.offset();
    this.mousePosition.x = this.dimension.width * 0.5 - (e.pageX - _offset.left);
    this.mousePosition.y = this.dimension.height * 0.5 - (e.pageY - _offset.top);
    this.update();
  }
  handleOrientation(e) {
    this.mousePosition.x = e.beta;
    this.mousePosition.y = e.gamma;
    this.update();
  }
  update() {
    this.filter.scale.x = this.mousePosition.x * this.displacementFactor;
    this.filter.scale.y = this.mousePosition.y * this.displacementFactor;
  }
  /**
   * Size the component.
   * Better rely on the element height, 'cause on mobile we set the height in css, on desktop we want to take all the viewport space
   * @param w The viewport width
   * @param h The viewport height
   */
  resize() {
    const _w = $(window).width();
    if (_w) {
      this.dimension.width = this.$el.width();
      this.dimension.height = this.$el.height();
      this.dimension.ratio = this.dimension.width / this.dimension.height;
      this.app.renderer.resize(this.dimension.width, this.dimension.height);
    }
    if (this.filter) {
      let _scale;
      if (this.dimension.ratio < this.imageDimension.ratio) {
        _scale = this.dimension.height / this.imageDimension.height;
      } else {
        _scale = this.dimension.width / this.imageDimension.width;
      }
      this.imageContainer.x = (this.dimension.width - this.imageDimension.width * _scale) * 0.5;
      this.imageContainer.y = (this.dimension.height - this.imageDimension.height * _scale) * 0.5;
      this.imageContainer.scale.x = this.imageContainer.scale.y = _scale;
    }
  }
}
window.addEventListener("load", (e) => {
  if ($(".js-hero-block-3d").length) {
    $(".js-hero-block-3d").each((index, heroBlockHTMLElement) => {
      const heroblock3d = new HeroBlock3D(heroBlockHTMLElement);
      window.addEventListener("resize", (e2) => {
        heroblock3d.resize();
      });
    });
  }
});
