const MagentoEcommerceConnector = await import("../../magento/ecommerceConnector/ecommerceConnector").then((module) => module.default.getInstance());
export default class HeroBlockEcommerceConnector {
  authorData;
  magentoEcommerceConnector = MagentoEcommerceConnector;
  constructor(authorData) {
    this.authorData = authorData;
    this.magentoEcommerceConnector = MagentoEcommerceConnector;
  }
  async getProductData() {
    let product = void 0;
    const skus = [this.authorData.sku];
    const productCatalog = MagentoEcommerceConnector.fetchProductsBySku(skus);
    await productCatalog.then((catalog) => {
      const productData = catalog.get(this.authorData.sku);
      if (productData) {
        const url = new URL(productData.image.url);
        const priceObject = productData.price_range.minimum_price.final_price;
        product = {
          sku: productData.sku,
          title: productData.name,
          description: productData.description,
          linkTarget: this.authorData.linkTargetProduct,
          link: productData.image.url && productData.url ? url.origin + this.magentoEcommerceConnector.getRootCountryLanguagePath() + productData.url : "",
          thumb: productData.image.url,
          priceValue: priceObject.value,
          priceCurrency: priceObject.currency
        };
      }
    });
    return product;
  }
}
