const Analytics = await import("./../../utils/Analytics").then(
  (module) => new module.default()
);
const Globals = await import("./../../core/globals").then((module) => module);
import HeroBlockEcommerceConnector from "./HeroBlockEcommerceConnector";
class HeroBlock {
  self;
  actionLink = ".cmp-teaser__action-link";
  subContent = ".cmp-teaser__subcontent";
  subcontentLink = ".cmp-teaser__subcontent__description p a";
  $subContentContainer;
  subContentAuthorProductData;
  subContentProductData;
  analytics = Analytics;
  constructor(heroBlock) {
    this.self = heroBlock;
    if (this.self.getAttribute("data-init")) {
      return;
    }
    if ($(this.self).hasClass("height-80-percent")) {
      const eightyPercentHeight = Math.round(Globals.windowInnerHeight * 0.8);
      const isDesktop = window.innerWidth >= Globals.viewport.lg;
      if (isDesktop) {
        $(this.self).css("height", eightyPercentHeight);
      }
    }
    this.$subContentContainer = $(this.self).find(this.subContent);
    if (this.$subContentContainer.length > 0) {
      this.subContentAuthorProductData = this.$subContentContainer.data("productdata");
      if (this.subContentAuthorProductData && this.subContentAuthorProductData.showProductData) {
        this.initShowProductData();
      }
    }
    this.self.setAttribute("data-init", "true");
    $(this.self).find(".cmp-teaser__subcontent__description p a").each((index, item) => {
      $(item).on("click", (event) => this.textClick(event));
    });
  }
  initShowProductData() {
    this.emptySubContentContainer();
    const heroBlockEcommerceConnector = new HeroBlockEcommerceConnector(
      this.subContentAuthorProductData
    );
    heroBlockEcommerceConnector.getProductData().then((productData) => {
      this.subContentProductData = productData;
      if (productData) {
        this.$subContentContainer.replaceWith(
          this.createSubContentWithProductData()
        );
        this.setAnalyticEvents();
      }
    });
  }
  emptySubContentContainer() {
    this.$subContentContainer.removeClass("cmp-teaser__subcontent");
    this.$subContentContainer.empty();
  }
  setAnalyticEvents() {
    $(this.self).find(".heroblock-subContent__link").on("click", (e) => this.viewDetailsAnalytics(e));
  }
  viewDetailsAnalytics(event) {
    const list = "PAA hero block";
    const module = "PAA hero block";
    const allSubContentLink = $(".heroblock-subContent__link");
    let position = 1;
    for (const index in allSubContentLink) {
      if (allSubContentLink[index] == event.currentTarget) {
        position = parseInt(index) + 1;
      }
    }
    const brand = this.$subContentContainer.data("brandname");
    const product = {
      name: this.subContentProductData.title,
      id: this.subContentProductData.sku,
      price: this.subContentProductData.priceValue,
      brand,
      position
    };
    this.analytics.sendAnalyticsEventProductClick(module, list, product);
  }
  createSubContentWithProductData() {
    return `<div class="heroblock-subContent">
              <div class="heroblock-subContent__image">
                <img src=${this.subContentProductData.thumb}/>
              </div>
              <div class="heroblock-subContent__container-text">
                <div class="heroblock-subContent__title">${this.subContentProductData.title}</div>
                <div class="heroblock-subContent__description">${this.subContentProductData.description}</div>
                <div class="heroblock-subContent__price">
                  <div class="heroblock-subContent__priceValue">${window.Granite.I18n.get(
      "${0}",
      this.subContentProductData.priceValue,
      "Price and Currency Sign Displayed Together"
    )}</div>
                  <div class="heroblock-subContent__priceCurrency">${this.subContentProductData.priceCurrency}</div>
                </div>
                <a class="heroblock-subContent__link" target=${this.subContentProductData.linkTarget} href=${this.subContentProductData.link}>${window.Granite.I18n.get("View details")}</a>
              </div>
            </div>`;
  }
  textClick(event) {
    const $current = $(event.currentTarget);
    const action = $current.text();
    const label = $current.prop("href");
    this.analytics.sendAnalyticsEventUserAction("text link", action, label);
  }
}
window.addEventListener("load", (e) => {
  if ($(".teaser-hero-block").length) {
    $(".teaser-hero-block").each((index, heroBlockHTMLElement) => {
      new HeroBlock(heroBlockHTMLElement);
    });
  }
});
export default HeroBlock;
